import request from '@/utils/request';
// 我的学分列表
export const getStudyScoreList = params => request.post(`study/me/study/score`, params);
// 学分规则
export const getCreditRule = () => request.get(`study/me/studyscore/rule`);
// 我的学时列表
export const getStudyTimeList = params => request.post(`study/me/study/time`, params);
// 我的积分列表
export const getStudyIntegralList = params => request.post(`study/me/study/integral`, params);
// 积分 规则
export const getStudyIntegralRule = params => request.get(`study/me/integral/rule`, params);
// 学习档案 详情
export const getArchive = (id, params) => request.post(`study/me/member/archive/${id}/detail`, params);