<template>
  <div class="numerical">
    <div class="item">
      <span class="value" style="color: #17b87e">
        <strong>
          {{ staticInfo.sum }}
        </strong>
      </span>
      <span class="label">
        {{ $t("mytime.total_hours") }}
        <!-- 总学时 -->
      </span>
    </div>
    <div class="item">
      <span class="value" style="color: #377dff">
        <strong>{{ staticInfo.year }}</strong>
      </span>
      <span class="label">
        {{ $t("mytime.year_hours") }}
        <!-- 本年获得学时 -->
      </span>
    </div>
    <div class="item">
      <span class="value" style="color: #f90">
        <strong>{{ staticInfo.month }}</strong>
      </span>
      <span class="label">
        {{ $t("mytime.month_hours") }}
        <!-- 本月获得学时 -->
      </span>
    </div>
  </div>
  <div class="name">{{ $t("mytime.all_record") }}</div>
  <div class="search">
    <a-range-picker
      v-model:value="queryParam.date"
      format="YYYY-MM-DD"
      valueFormat="YYYY-MM-DD"
    />
    <div class="search-btn">
      <a-button type="primary" @click="handleSearch">
        {{ $t("XB_Search") }}
        <!-- 搜索 -->
      </a-button>
    </div>
  </div>
  <div class="table-wrap">
    <a-table
      :columns="columns"
      :data-source="state.dataSource"
      rowKey="detailId"
      :bordered="false"
      :pagination="pagination"
      :loading="state.loading"
      @change="handleTableChange"
    >
      <template #action="{ text }">
        <a-tooltip placement="topLeft" :title="text">{{ text }}</a-tooltip>
      </template>
      <template #type="{ text }">
        <span>{{ resourceType[text] }}</span>
      </template>
      <template #time="{ text }">
        <span>{{ dateFormat(text) }}</span>
      </template>
    </a-table>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, toRaw } from "vue";
import { getStudyTimeList } from "@/api/mine";
import { useFetchData } from "@/utils/useFetchData";
import { dateFormat } from "@/utils/tools";
export default {
  setup() {
    const { t: $t } = useI18n();

    const staticInfo = reactive({
      sum: 0,
      year: 0,
      month: 0,
    });

    const stateData = reactive({
      columns: [
        {
          title: $t("mytime.table.name_of_resource"),
          // title: "资源名称",
          dataIndex: "resourceName",
          ellipsis: true,
          width: 200,
          slots: { customRender: "tooltip" },
        },
        {
          title: $t("mytime.table.resource_type"),
          // title: "资源类型",
          dataIndex: "resourceType",
          ellipsis: true,
          slots: { customRender: "type" },
        },
        {
          title: $t("CM_Period"),
          // title: "学时",
          dataIndex: "integral",
          ellipsis: true,
          width: 100,
        },
        {
          title: $t("mytime.table.time"),
          // title: "时间",
          dataIndex: "createTime",
          ellipsis: true,
          width: 170,
          slots: { customRender: "time" },
        },
        {
          title: $t("mytime.table.descr"),
          // title: "备注说明",
          dataIndex: "reason",
          ellipsis: true,
          width: 250,
          slots: { customRender: "tooltip" },
        },
      ],
    });
    const resourceType = {
      1: $t("Pub_Tab_CT"), // 面授
      2: $t("subject"), // 课程专题
      3: $t("credits.online_courses"), // 在线课程
      6: $t("credits.training_task"), // 培训任务
      7: $t("Pub_LP"), // 学习地图
      8: $t("CM_Live"), // 直播
      10: $t("Sys_Exam"), // 考试
    };
    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return getStudyTimeList(form).then((res) => {
        staticInfo.sum = res.data.sum;
        staticInfo.year = res.data.year;
        staticInfo.month = res.data.month;
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };
    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 10,
      tableSize: "middle", // 'default' | 'middle' | 'small'
      stripe: false,
    });
    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });

      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["10", "30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    let queryParam = reactive({
      date: [],
    });
    const handleSearch = () => {
      setPageInfo({ current: 1, ...toRaw(queryParam) });
      reload();
    };
    return {
      dateFormat,
      handleSearch,
      staticInfo,
      ...toRefs(stateData),
      state,
      queryParam,
      handleTableChange,
      pagination,
      resourceType,
    };
  },
};
</script>

<style lang="less" scoped>
.numerical {
  padding: 24px 0;
  .mixinFlex(space-between);
  .item {
    width: 289px;
    height: 96px;
    background-color: #f5f7fb;
    border-radius: 8px;
    .mixinFlex(center;center;column);
    .value {
      font-size: 14px;
      font-weight: 400;
      line-height: 35px;
      height: 35px;
      strong {
        font-size: 32px;
        font-weight: 700;
      }
    }
    .label {
      color: #999;
      font-size: 12px;
      margin-top: 8px;
    }
  }
}
.name {
  color: #333;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  padding: 16px 0;
  border-bottom: 1px solid #f4f4f4;
}
.search {
  padding: 24px 0;
  .mixinFlex(flex-start; center);
  .search-btn {
    margin-left: 16px;
  }
}
</style>
