<template>
  <div class="numerical">
    <div class="item">
      <span class="value" style="color: #17b87e">
        <strong>
          {{ extendData.doneCourses || 0 }}
        </strong>
      </span>
      <span class="label">
        {{ $t("mine.complete_online_course") }}
        <!-- 完成在线课程数 -->
      </span>
    </div>
    <div class="item">
      <span class="value" style="color: #377dff">
        <strong>
          {{
            extendData.totalTime > 0
              ? (extendData.totalTime / 60).toFixed(0)
              : 0
          }}
        </strong>
        min
      </span>
      <span class="label">
        {{ $t("LB_Period_TotalStudyTime") }}
        <!-- 总学习时长 -->
        <a-popover trigger="hover">
          <template #content>
            <div class="popover-content">
              {{ $t("mine.month_learn_hours") }}
            </div>
          </template>
          <ExclamationCircleOutlined style="color: #999; padding-left: 4px" />
        </a-popover>
      </span>
    </div>
    <div class="item">
      <span class="value" style="color: #f90">
        <strong> {{ extendData.monthRanks || 0 }} </strong>
      </span>
      <span class="label">
        {{ $t("mine.month_study_time_rank") }}
        <!-- 本月学习时长排行 -->
        <a-popover trigger="hover">
          <template #content>
            <div class="popover-content">
              {{ $t("mine.month_learn_hours") }}
            </div>
          </template>
          <ExclamationCircleOutlined style="color: #999; padding-left: 4px" />
        </a-popover>
      </span>
    </div>
    <div class="item">
      <span class="value" style="color: #ff6ddf">
        <strong>
          {{
            extendData.monthTime > 0
              ? (extendData.monthTime / 60).toFixed(0)
              : 0
          }}
        </strong>
        min
      </span>
      <span class="label">
        {{ $t("mine.month_study_time") }}
        <!-- 本月学习时长 -->
        <a-popover trigger="hover">
          <template #content>
            <div class="popover-content">
              {{ $t("mine.month_learn_hours") }}
            </div>
          </template>
          <ExclamationCircleOutlined style="color: #999; padding-left: 4px" />
        </a-popover>
      </span>
    </div>
  </div>
  <div class="filter">
    <div
      class="item"
      :class="{ active: typeActive === index }"
      v-for="(item, index) in taskData"
      :key="index"
      @click="typeChange(index)"
      style="position: relative;"
    >
      {{ item.name }}
      <span class="badge" v-if="typeCounts[index]>0">
        <div style="margin-top: -7px;">{{typeCounts[index]}}</div>
      </span>
    </div>
  </div>
  <a-spin :spinning="listLoading">
    <div class="cards-wrap">
      <template v-if="dataList.length">
        <div class="cards">
          <div class="item" v-for="(item, index) in dataList" :key="index">
            <TaskCard :showTypeName="typeActive == 0" :dataSource="item" />
          </div>
        </div>
        <div class="page-Wrap">
          <a-pagination
            show-quick-jumper
            hideOnSinglePage
            :defaultPageSize="15"
            v-model:current="currentPage"
            :total="pageTotal"
            @change="pageChange"
          >
            <template #itemRender="{ type, originalElement }">
              <a class="page-a" v-if="type === 'prev'">上一页</a>
              <a class="page-a" v-else-if="type === 'next'">下一页</a>
              <renderVNode v-else :vnode="originalElement"></renderVNode>
            </template>
          </a-pagination>
        </div>
      </template>
      <div class="empty-wrap" v-if="!dataList.length && !listLoading">
        <a-empty
          :image="require('@/assets/image/no_data_2.png')"
          :image-style="{ height: '189px' }"
          style="padding: 60px 0"
        >
          <template #description>
            <span class="empty-desc">
              {{ $t("mine.no_task") }}
              <!-- 暂无任务派发，去观看其他精彩课程吧～ -->
            </span>
          </template>
        </a-empty>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { reactive, toRefs } from "vue";
import { extend } from "@/api/user";
import { newTaskList,taskTypeCounts } from "@/api/project";
import TaskCard from "@/components/new/mine/TaskCard.vue";
const renderVNode = (_, { attrs: { vnode } }) => vnode;
export default {
  components: {
    TaskCard,
    renderVNode,
  },
  setup() {
    const state = reactive({
      extendData: {
        doneCourses: 0,
      },
      typeActive: 0,
      taskData: [],
      dataList: [],
      listLoading: true,
      currentPage: 1,
      pageTotal: 1,
      typeCounts:{},
    });

    extend().then((res) => {
      state.extendData = res.data;
    });

    const getList = () => {
      const type = state.taskData[state.typeActive].taskType;
      newTaskList({
        page: state.currentPage,
        pageSize: 15,
        taskType: type,
      }).then((res) => {
        if (res.ret !== 0) return;
        state.dataList = res.data.list || [];
        state.pageTotal = res.data.totals;
        state.listLoading = false;
      });
    };

    const getTaskTypeCounts = () => {
      const type = state.taskData[state.typeActive].taskType;
      taskTypeCounts({
        taskType: type,
      }).then((res) => {
        state.typeCounts = {};
        console.log(state.taskData);
        for(let i=0;i<state.taskData.length;i++){
          let type = state.taskData[i].taskType;
          let typeCount = 0;
          for(let j = 0 ;j<type.length;j++){
            if(res.data.rsult[type[j]]!=undefined){
              typeCount+=res.data.rsult[type[j]];
            }
          }
          state.typeCounts[i]=typeCount;
        console.log(type);
        console.log(state.typeCounts);
        }
      });
    }

    const init = (data) => {
      state.taskData = data;
      getList();
      getTaskTypeCounts();
    };

    const typeChange = (index) => {
      state.typeActive = index;
      reloadList();
    };

    const reloadList = () => {
      state.dataList = [];
      state.currentPage = 1;
      state.listLoading = true;
      getList();
    };

    const pageChange = (n) => {
      state.currentPage = n;
      state.listLoading = true;
      getList(true);
    };

    return {
      ...toRefs(state),
      init,
      typeChange,
      pageChange,
    };
  },
};
</script>

<style lang="less" scoped>
.numerical {
  padding: 24px 0;
  border-bottom: 1px solid #f4f4f4;
  .mixinFlex(space-between);
  .item {
    width: 212px;
    height: 96px;
    background-color: #f5f7fb;
    border-radius: 8px;
    .mixinFlex(center;center;column);
    .value {
      font-size: 14px;
      font-weight: 400;
      line-height: 35px;
      height: 35px;
      strong {
        font-size: 32px;
        font-weight: 700;
      }
    }
    .label {
      color: #999;
      font-size: 12px;
      margin-top: 8px;
    }
  }
}
.filter {
  padding: 24px 0;
  .mixinFlex();
  flex-wrap: wrap;
  .item {
    margin-right: 16px;
    margin-bottom: 8px;
    color: #333;
    font-size: 16px;
    line-height: 30px;
    padding: 0 10px;
    border: 1px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    &.active {
      color: @color-theme;
      border: 1px solid @color-theme;
    }
    &:hover {
      color: @color-theme;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}
.cards-wrap {
  min-height: 300px;
}
.cards {
  .mixinFlex();
  flex-wrap: wrap;
  .item {
    margin-right: 20px;
    margin-bottom: 20px;
    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
.page-Wrap {
  .page-a {
    padding: 0 4px;
    height: 32px;
    display: inline-block;
  }

  ::v-deep(.ant-pagination-item-active) {
    border: 1px solid @color-theme;

    a {
      color: @color-theme !important;
    }
  }
}
.empty-wrap {
  .empty-desc {
    margin: 20px 0;
    color: #999;
    font-size: 14px;
    line-height: 20px;
  }
  .empty-go {
    background-color: @color-theme;
    color: #fff;
    padding: 6px 16px;
    line-height: 20px;
    border-radius: 4px;
  }
}

.badge{
  min-width: 18px;
  background-color: #FF4D50;
  color: white;
  border-radius: 50%;
  padding: 1px;
  position: absolute;
  top: -7px;
  right: -3px;
  font-size: 13px;
  height: 18px;
  text-align: center;
}
</style>
