<template>
  <a-modal
    wrapClassName="level-modal"
    :width="600"
    :bodyStyle="{ padding: 0 }"
    :centered="true"
    :maskClosable="false"
    :footer="null"
    :visible="visible"
    @cancel="cancel"
  >
    <div class="level">
      <div class="level-head">
        <div class="level-head-now">{{ $t('mine.current_level') }}</div>
        <!-- 当前等级 -->
        <img class="level-head-img" :src="user.levelImage" alt="level" />
        <div class="level-head-name">{{ user.levelName }}</div>
        <div class="level-head-num">
          <span>{{ user.totalIntegral }}</span>
          /
          {{ user.nextLevelIntegral }}
        </div>
        <div class="level-head-progress">
          <a-progress
            :showInfo="false"
            :percent="
              user.totalIntegral >= user.nextLevelIntegral
                ? 100
                : (user.totalIntegral / user.nextLevelIntegral) * 100
            "
            trailColor="rgba(255,255,255,0.4)"
            strokeColor="#fff"
          />
        </div>
        <div class="level-head-detail" v-if="user.levelName">
          {{
            // 您的等级已经登顶，难道是传说中的大神？接受众人膜拜吧
            user.totalIntegral >= user.nextLevelIntegral
              ? $t('mine.level_top')
              : $t('mine.get_another_nth_points_to_upgrade_to', [user.nextLevelIntegral - user.totalIntegral, Number(user.levelName.slice(3)) + 1])
          }}
          <!-- 再获取 点积分值升级到Lv-->
        </div>
      </div>
      <div class="level-list">
        <div class="level-list-title">{{ $t('mine.level_comparison_table') }}</div>
        <!-- 等级对照表 -->
        <div class="level-list-th">
          <span>{{ $t('CM_Level') }}</span>
          <!-- 等级 -->
          <span>{{ $t('mine.tier_points') }}</span>
          <!-- 等级积分值 -->
        </div>
        <div class="level-list-tb">
          <div
            class="level-list-tr"
            v-for="item in levelData"
            :key="item.levelId"
          >
            <span>{{ item.levelName }}</span>
            <span>{{ item.minIntegral }}</span>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent, reactive, toRefs } from "vue";
import { levelList } from "@/api/user";

export default defineComponent({
  props: {
    user: {
      type: Object,
      default: {},
    },
  },
  setup() {
    const state = reactive({
      visible: false,
      levelData: [],
    });

    levelList().then((res) => {
      state.levelData = res.data || [];
    });

    const show = () => {
      state.visible = true;
    };

    const cancel = () => {
      state.visible = false;
    };

    return {
      ...toRefs(state),
      show,
      cancel,
    };
  },
});
</script>
<style lang="less" scoped>
.level {
  &-head {
    height: 261px;
    width: 100%;
    position: relative;
    padding: 94px 37px 0;
    background: linear-gradient(180deg, #ccd8ff 0%, #b3c5ff 100%);
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url("~@/assets/image/level-bg.png");
    }
    &-now {
      width: 103px;
      height: 38px;
      border-radius: 0 19px 19px 0;
      background: linear-gradient(90deg, #c5cfee 0%, #aab7e1 99%);
      position: absolute;
      top: 23px;
      left: 0;
      font-size: 16px;
      line-height: 38px;
      color: #404c73;
      text-align: center;
    }
    &-img {
      width: 173px;
      height: 173px;
      position: absolute;
      top: 10px;
      right: 40px;
    }
    &-name {
      font-size: 58px;
      line-height: 42px;
      color: #404c73;
      position: inherit;
    }
    &-num {
      font-size: 14px;
      color: #404c73;
      margin-top: 23px;
      line-height: 24px;
      position: inherit;
      span {
        font-size: 20px;
      }
    }
    &-detail {
      font-size: 14px;
      color: #8088a2;
      position: inherit;
      margin-top: 6px;
    }
  }
  &-list {
    &-title {
      font-size: 14px;
      line-height: 50px;
      text-align: center;
      color: #666666;
      border-bottom: 1px solid #eaf0ee;
    }
    &-th,
    &-tr {
      .mixinFlex(center; center);
      font-size: 12px;
      line-height: 60px;
      text-align: center;
      color: #202020;
      border-bottom: 1px solid #eaf0ee;
      span {
        width: 50%;
      }
    }
    &-th {
      margin: 0 38px;
    }
    &-tr {
      line-height: 56px;
      &:last-child {
        border-bottom: none;
      }
    }
    &-tb {
      padding: 0 38px;
      max-height: 300px;
      overflow-y: auto;
    }
  }
}
</style>
<style lang="less">
.level-modal {
  .ant-modal-close-x {
    width: 45px;
    height: 45px;
    line-height: 45px;
    color: #fff;
  }
}
</style>
