<template>
  <div class="numerical">
    <div class="item">
      <span class="value" style="color: #17b87e">
        <strong>
          {{ staticInfo.sum }}
        </strong>
      </span>
      <span class="label">
        {{ $t("integral.level_integral") }}
        <!-- 等级积分 -->
      </span>
    </div>
    <div class="item">
      <span class="value" style="color: #377dff">
        <strong>{{ staticInfo.usablesum }}</strong>
      </span>
      <span class="label">
        {{ $t("integral.available_integral") }}
        <!-- 可用积分 -->
      </span>
    </div>
    <div class="item">
      <span class="value" style="color: #f90">
        <strong>{{ staticInfo.year }}</strong>
      </span>
      <span class="label">
        {{ $t("integral.year_integral") }}
        <!-- 本年获得积分 -->
      </span>
    </div>
    <div class="item">
      <span class="value" style="color: #f90">
        <strong>{{ staticInfo.month }}</strong>
      </span>
      <span class="label">
        {{ $t("integral.month_integral") }}
        <!-- 本月获得积分 -->
      </span>
    </div>
  </div>
  <div class="tabs">
    <div
      class="tabs-item"
      :class="activeIndex === index && 'active'"
      v-for="(item, index) in tabList"
      :key="index"
      @click="changeTab(index)"
    >
      {{ item }}
    </div>
  </div>
  <div class="tab-wrap" v-show="activeIndex == 0">
    <div class="search">
      <a-range-picker
        v-model:value="queryParam.date"
        format="YYYY-MM-DD"
        valueFormat="YYYY-MM-DD"
      />
      <div class="search-btn">
        <a-button type="primary" @click="handleSearch">
          {{ $t("XB_Search") }}
          <!-- 搜索 -->
        </a-button>
      </div>
    </div>
    <div class="table-wrap">
      <a-table
        :columns="columns"
        :data-source="state.dataSource"
        :rowKey="(record, index) => index"
        :bordered="false"
        :pagination="pagination"
        :loading="state.loading"
        @change="handleTableChange"
      >
        <template #tooltip="{ text }">
          <a-tooltip placement="topLeft" :title="text">{{ text }}</a-tooltip>
        </template>
        <template #time="{ text }">
          <span>{{ dateFormat(text) }}</span>
        </template>
      </a-table>
    </div>
  </div>
  <div class="tab-wrap" v-show="activeIndex == 1">
    <div class="explain">
      {{ $t("mine.score_rule_tip") }}
      <!-- 进行以下动作会获得积分奖励，在一个动作周期内你最多得到的奖励次数有限制。 -->
    </div>
    <div class="table-wrap">
      <a-table
        :columns="ruleColumns"
        :data-source="ruleList"
        rowKey="integralId"
        :bordered="false"
        :pagination="false"
      >
        <template #integralTimesTitle>
          {{ $t("integral.table.cycle") }}
          <!-- 动作周期 -->
          <a-popover trigger="hover">
            <template #content>
              <div class="popover-content">
                {{ $t("integral.cycle_tip") }}
                <!-- 一次性：该动作只需要完成1次，奖励1次。不限制：该动作可以完成多次，奖励多次。 -->
              </div>
            </template>
            <ExclamationCircleOutlined style="color: #999; padding-left: 4px" />
          </a-popover>
        </template>
        <template #integralTimes="{ record }">
          {{
            record.integralTimes == 1
              ? $t("integral.table.cycle_state", 1)
              : $t("integral.table.cycle_state", 2)
          }}
          <!-- "一次性" : "不限制" -->
        </template>
        <template #integralLimit="{ record }">
          {{
            record.integralTimes == 1
              ? "/"
              : record.integralLimit == 0
              ? $t("integral.table.cycle_state", 2)
              : // 不限制
                record.integralLimit
          }}
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, ref, toRefs, toRaw } from "vue";
import { getStudyIntegralList, getStudyIntegralRule } from "@/api/mine";
import { useFetchData } from "@/utils/useFetchData";
import { dateFormat } from "@/utils/tools";
export default {
  setup() {
    const { t: $t } = useI18n();
    const tabList = reactive([$t("integral.all_record")]); // 全部积分记录
    const activeIndex = ref(0);
    const changeTab = (i) => {
      if (activeIndex.value === i) return;
      activeIndex.value = i;
    };

    const staticInfo = reactive({
      sum: 0,
      usablesum: 0,
      year: 0,
      month: 0,
    });

    const stateData = reactive({
      columns: [
        {
          title: $t("integral.table.action"),
          // title: "动作名称",
          dataIndex: "action",
          ellipsis: true,
          width: 200,
          slots: { customRender: "tooltip" },
        },
        {
          title: $t("integral.table.integral"),
          // title: "积分",
          width: 100,
          dataIndex: "integral",
          ellipsis: true,
        },
        {
          title: $t("mytime.table.time"),
          // title: "时间",
          dataIndex: "createTime",
          ellipsis: true,
          width: 200,
          slots: { customRender: "time" },
        },
        {
          title: $t("mytime.table.descr"),
          // title: "备注说明",
          dataIndex: "reason",
          ellipsis: true,
          slots: { customRender: "tooltip" },
        },
      ],
      ruleList: [],
      ruleColumns: [
        {
          title: $t("integral.table.action"),
          // title: "动作名称",
          dataIndex: "integralName",
          width: 120,
        },
        {
          dataIndex: "integralTimes",
          slots: {
            title: "integralTimesTitle",
            customRender: "integralTimes",
          },
          width: 120,
          align: "center",
        },
        {
          title: $t("integral.table.descr"),
          // title: "描述说明",
          dataIndex: "intro",
          width: 400,
        },
        {
          title: $t("integral.table.bonus_points"),
          // title: "奖励积分",
          dataIndex: "integral",
          slots: { customRender: "integral" },
          width: 120,
          align: "center",
        },
        {
          title: $t("integral.table.daily_limit"),
          // title: "每日积分上限",
          dataIndex: "integralLimit",
          slots: { customRender: "integralLimit" },
          width: 130,
          align: "center",
        },
      ],
    });
    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return getStudyIntegralList(form).then((res) => {
        staticInfo.sum = res.data.sum;
        staticInfo.usablesum = res.data.usablesum;
        staticInfo.year = res.data.year;
        staticInfo.month = res.data.month;
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };
    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 10,
      tableSize: "middle", // 'default' | 'middle' | 'small'
      stripe: false,
    });
    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });

      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["10", "30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    let queryParam = reactive({
      date: [],
    });
    const handleSearch = () => {
      setPageInfo({ current: 1, ...toRaw(queryParam) });
      reload();
    };

    getStudyIntegralRule().then((res) => {
      stateData.ruleList = res.data || [];
      if (stateData.ruleList.length)
        tabList.push($t("Lab_MyIntegral_IntegralRule"));
      // 积分规则
    });

    return {
      dateFormat,
      tabList,
      activeIndex,
      changeTab,
      handleSearch,
      staticInfo,
      ...toRefs(stateData),
      state,
      queryParam,
      handleTableChange,
      pagination,
    };
  },
};
</script>

<style lang="less" scoped>
.numerical {
  padding: 24px 0;
  .mixinFlex(space-between);
  .item {
    width: 212px;
    height: 96px;
    background-color: #f5f7fb;
    border-radius: 8px;
    .mixinFlex(center;center;column);
    .value {
      font-size: 14px;
      font-weight: 400;
      line-height: 35px;
      height: 35px;
      strong {
        font-size: 32px;
        font-weight: 700;
      }
    }
    .label {
      color: #999;
      font-size: 12px;
      margin-top: 8px;
    }
  }
}
.tabs {
  .mixinFlex(flex-start; center);
  padding: 16px 0 16px;
  border-bottom: 1px solid #f4f4f4;
  &-item {
    color: #333;
    font-size: 16px;
    line-height: 26px;
    position: relative;
    margin-right: 32px;
    cursor: pointer;
    &::after {
      content: "";
      width: 100%;
      height: 4px;
      background-color: #fff;
      position: absolute;
      bottom: -16px;
      left: 0;
      border-radius: 2px;
      transition: all 0.2s;
    }
    &.active {
      color: var(--theme);
      &::after {
        background-color: var(--theme);
      }
    }
  }
}
.search {
  padding: 24px 0;
  .mixinFlex(flex-start; center);
  .search-btn {
    margin-left: 16px;
  }
}
.tab-wrap {
  .explain {
    font-size: 14px;
    color: #666;
    line-height: 20px;
    padding: 24px 0;
  }
}
</style>
