<template>
  <div class="card" @click="jump">
    <div class="type-name" v-if="showTypeName">
      {{ taskType[dataSource.taskType].name }}
    </div>
    <div class="content">
      <div class="icon">
        <img
          :src="
            require(`@/assets/image/mine/tasks/${
              taskType[dataSource.taskType].code
            }.png`)
          "
          alt="icon"
        />
      </div>
      <div class="info">
        <div class="top">
          <h5>{{ dataSource.taskName }}</h5>
          <div class="date">
            <template
              v-if="dataSource.taskType === 6 && dataSource.lastTime === 0"
            >
              {{
                $t("study.learn_in", [
                  dataSource.effective,
                  mapDateType[dataSource.dateType],
                ])
              }}
            </template>
            <template v-else>
              <template v-if="dataSource.endTime < now">
                {{
                  dataSource.isOverdue === 1
                    ? $t("study.overdue_learn")
                    : $t("study.days_remaining", [0])
                }}
                <!-- "逾期可学" : "剩余0天" -->
              </template>
              <template v-else>{{ timeRmaining(dataSource.endTime) }}</template>
            </template>
          </div>
        </div>
        <div class="bottom">
          <div class="progress">
            <a-progress
              v-if="![10, 2].includes(dataSource.taskType)"
              :percent="dataSource.progress"
              size="small"
              :showInfo="false"
              :strokeWidth="4"
            />
          </div>
          <div class="percent">
            <span
              v-if="![10, 2].includes(dataSource.taskType)"
              style="color: #377dff"
            >
              {{ dataSource.progress }}%
            </span>
            <template v-else>
              <span v-if="now < dataSource.startTime" style="color: #e8673e">
                {{ $t("Pub_Tab_NotStart") }}
                <!-- 未开始 -->
              </span>
              <span v-else style="color: #44b44f">
                {{ $t("XB_UnderWay") }}
                <!-- 进行中 -->
              </span>
            </template>
          </div>
        </div>
      </div>
    </div>
    <homework
      ref="homeworkRef"
      @updata:homework="homeworkChange"
      v-if="dataSource.taskType == 5"
    />
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { resourceType } from "@/utils/business";
import { timeRmaining } from "@/utils/tools";
import homework from "@/components/homework/index.vue";
export default {
  name: "TaskCard",
  components: {
    homework,
  },
  props: {
    dataSource: {
      type: Object,
      default: {},
    },
    showTypeName: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { t: $t } = useI18n();
    const router = useRouter();
    let now = Date.parse(new Date()) / 1000;

    const homeworkRef = ref(null);
    const taskType = {
      1: { name: $t("MN_TrainingProject_MHome"), code: "project" }, // 项目培训
      11: { name: $t("MN_TrainingProject_MHome"), code: "project" }, // 项目培训
      14: { name: $t("MN_TrainingProject_MHome"), code: "project" }, // 项目培训
      6: { name: $t("Pub_LP"), code: "map" }, // 学习地图
      9: { name: $t("cm_newstaff"), code: "newstaff" }, // 新人培训
      5: { name: $t("cm_subject"), code: "subject" }, // 课程专题
      10: { name: $t("Sys_Exam"), code: "exam" }, // 考试
      2: { name: $t("daily_exam"), code: "cyclic" }, // 每日一练
      12: { name: $t("cm_activity"), code: "activity" }, // 活动
      16: { name: $t("Pub_Tab_CT"), code: "face" }, // 面授
      20: { name: $t("CM_Live"), code: "live" }, // 直播
    };
    const mapDateType = {
      1: $t("Pub_Day"), // "天"
      2: $t("mine.month"), // "月"
      3: $t("CM_Year"), // "年"
    };
    const jump = () => {
      let d = props.dataSource;
      switch (d.taskType) {
        case 1: // 培训项目 -- 项目管理
          router.push({
            path: "/project/detail",
            query: {
              id: d.taskId,
            },
          });
          break;
        case 11: // 培训项目 -- 任务督导
          router.push({
            path: "/project/detail",
            query: {
              id: d.taskId,
            },
          });
          break;
        case 14: // 培训项目 -- 培训实施
          router.push({
            path: "/project/detail",
            query: {
              id: d.taskId,
            },
          });
          break;
        case 6: // 学习地图
          router.push({
            path: "/map/detail",
            query: {
              id: d.taskId,
              temp: d.mapTemplate,
            },
          });
          break;
        case 9: // 新人培训
          router.push({ path: "/newstaff/detail", query: { id: d.taskId } });
          break;
        case 5: // 课程专题
          router.push({ path: "/subject/detail", query: { id: d.taskId } });
          break;
        case 10: // 考试
          if (d.reexamId) {
            router.push({
              path: "/exam/detail",
              query: {
                id: d.taskId,
                did: d.detailId,
                reexamId: d.reexamId,
                taskType: 23,
              },
            });
          } else {
            router.push({
              path: "/exam/detail",
              query: {
                id: d.taskId,
                did: d.detailId,
              },
            });
          }
          break;
        case 2: // 每日一练
          router.push({
            path: "/exam/detail",
            query: {
              id: d.taskId,
              did: d.detailId,
            },
          });
          break;
        case 12: // 活动
          router.push({
            path: "/questionnaire/detail",
            query: {
              source: "activity",
              id: d.taskId,
              did: d.detailId,
              resourceId: d.resourceId,
              progress: d.progress,
              name: d.taskName,
              complete: d.complete,
            },
          });
          break;
        case 16: // 面授
          router.push({
            path: "/train/detail",
            query: {
              id: d.taskId,
              did: d.detailId,
            },
          });
          break;
        case 20: // 直播
          router.push({
            path: "/live/detail",
            query: {
              id: d.taskId,
              did: d.detailId,
            },
          });
          break;
      }
    };

    return {
      now,
      resourceType,
      timeRmaining,
      jump,
      homeworkRef,
      taskType,
      mapDateType,
    };
  },
};
</script>

<style lang="less" scoped>
.card {
  width: 289px;
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(148, 148, 148, 0.1);
  transition-property: transform, box-shadow;
  transition-duration: 0.3s;
  background-color: #fff;
  cursor: pointer;
  .type-name {
    height: 37px;
    background-color: #f5f7fb;
    color: #333;
    font-size: 12px;
    line-height: 17px;
    padding: 10px 12px;
  }
  .content {
    padding: 20px 12px;
    .mixinFlex(space-between; center);
    .icon {
      .mixinImgWrap(48px; 48px);
      border-radius: 4px;
    }
    .info {
      width: calc(100% - 60px);
      .top {
        .mixinFlex(space-between; center);
        h5 {
          color: #333;
          font-size: 14px;
          font-weight: 600;
          .mixinEllipsis(20px);
          width: 138px;
          margin-bottom: 0;
        }
        .date {
          color: #999;
          font-size: 12px;
          line-height: 20px;
        }
      }
      .bottom {
        .progress {
          width: 138px;
        }
      }
      .percent {
        font-size: 16px;
        font-weight: 700;
        line-height: 17px;
      }
    }
  }
  .top {
    .mixinFlex(space-between);
    .progress {
      width: 156px;
      margin-right: 10px;
      h5 {
        font-size: 16px;
        margin-bottom: 0;
        line-height: 1.3;
      }
      &-bar {
        height: 19px;
      }
      &.str {
        width: 140px;
        margin-right: 0;
      }
    }
    .percent {
      font-size: 16px;
      font-family: DINAlternate-Bold, DINAlternate;
      &.str {
        line-height: 1;
        color: @color-theme;
        font-size: 14px;
      }
    }
  }
  .bottom {
    padding-top: 8px;
    font-size: 12px;
    color: #a7a7a7;
    .mixinFlex(space-between; center);
    .project {
      width: 160px;
      .mixinEllipsis(20px);
      color: #202020;
      font-size: 14px;
    }
  }
  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 0 15px 0 rgba(148, 148, 148, 0.25);
  }
}
</style>
