<template>
    <a-modal
      wrapClassName="sign-modal"
      :width="700"
      :bodyStyle="{ padding: 0 }"
      :centered="true"
      :maskClosable="false"
      :footer="null"
      :visible="visible"
      @cancel="cancel"
    >
      <div class="sign">
        <div class="status">
          <div class="top">{{ $t('mine.cumulative_signin', [always]) }}</div>
          <!-- 本月累计签到 天 -->
          <div class="bottom">
            <div >
              <a-select
                  v-if="clockStudys.length>1"
                  v-model:value="id"
                  style="width: 165px"
                  @change="clockStudyChange"
                >
                <a-select-option v-for="item in clockStudys" :value="item.id">{{item.name}}</a-select-option>
              </a-select>
              <div class="rows" v-if="clockStudys.length>0 && id>0">
                <div class="item">
                  <span class="label">{{ $t('要求时长') }}：</span>
                  <span style="color: coral;">{{ clockStudys.filter(item=>item.id ==id)[0].studyTime }}</span>{{ $t('分钟') }}
                </div>
              </div>
              <div class="rows" >
                <div class="item">
                  <span class="label">{{ $t('已学时长') }} ：</span>
                  <span style="color:coral;">{{ learnedTime }}</span>{{ $t('分钟') }}
                </div>
              </div>
              <div class="info"  v-if="clockStudys.length>0 && id>0">{{ $t('打卡可获得') }} {{ clockStudys.filter(item=>item.id ==id)[0].integral }} {{ $t('LB_Pro_Integral') }}</div>
              
            </div>
            <!-- 今日签到获得 积分 -->
            <div class="btns">
              <a-button type="primary" block @click="clockIn(null)">
                {{isClockIn?"已打卡":"学习打卡"}}
              </a-button>
            </div>
          </div>
        </div>
        <div class="calendar">
          <div class="box">
            <Calendar
              :choosable="false"
              :isReissue = "isReissue"
              :weekdays = "weekdays"
              :highlightDates="signDates"
              @reissue="clockIn"
              @monthChange="monthChange"
            />
          </div>
        </div>
      </div>
    </a-modal>
  </template>
  <script>
  import { defineComponent, reactive, toRefs, getCurrentInstance,watch,onMounted  } from "vue";
  import { useStore } from "vuex";
  import moment from "moment";
  import Calendar from "@/components/Calendar.vue";
  import { sign, getClockInStudys,clockInStudyMember, learnTimes } from "@/api/user";
  
  export default defineComponent({
    components: {
      Calendar,
    },
    props: {
      clockStudys: {
        type: Array,
        default: [],
      },
    },
    setup(props) {

      onMounted(() => {
        watch(
            () => props.clockStudys,
            (newValue) => {
              // console.log('newValue');
              // console.log(newValue);
              //   if(newValue.length>0){
              //     state.id = newValue[0].id;
              //   }
            }
        );
      });

      const { proxy } = getCurrentInstance();
      const store = useStore();
      const state = reactive({
        visible: false,
        signDates: [],
        isClockIn: false,
        always: 0,
        integral: 0,
        id : 0,
        learnedTime:0,
        isReissue:false,
        weekdays:'',
      });
      
      const clockIn = (item) => {
        if(state.isClockIn&&item == null){
            return false;
        }
        let param = {
            id : state.id,
            isReissue :item==null? 0:1,
            reissueDateText:item==null?'':item.value,
        }
        clockInStudyMember(param).then((res) => {
            if(res.ret==0){
                if(item == null){
                  state.isClockIn= true;
                }
                proxy.$message.success("学习打卡成功");
                getSignList(moment().format("YYYY") ,moment().format("MM") );
            }else{

            }
        });
      }
      
      const clockStudyChange = (value) => {
        let clockStudy = props.clockStudys.filter(item=>item.id ==value)[0];
        state.isReissue = clockStudy.isReissue;
        state.weekdays = clockStudy.weekdays;
      }
      
      const getLearnTimes = () => {
        let param = {
            key : 'day',
            value:7,
        }
        learnTimes(param).then((res) => {
            if(res.ret==0){
             state.learnedTime = parseInt(res.list[6].totalTime  / 60);
            }else{

            }
        });
      }
  
      const getSignList = (years,months) => {
        getClockInStudys({ years: parseInt(years) ,months:parseInt(months) }).then((res) => {
          state.signDates = res.data.list.map((item) => {
            let month = item.months<10?"0"+item.months:""+item.months;
            let days = item.days<10?"0"+item.days:""+item.days;
            return item.years+'-'+month+'-'+days
          });
          var date = new Date();
          var day =  date.getDate();
          //如果当前已打卡
          if(res.data.list[ res.data.list.length-1].days == day){
            state.isClockIn = true;
            1==1;
          }
          console.log( state.signDates);
          state.always= res.data.list.length;

        });
      };
  
      const show = () => {
        state.visible = true;
        getSignList(moment().format("YYYY") ,moment().format("MM") );
        getLearnTimes();
        state.id =  props.clockStudys[0].id;
        state.integral=  props.clockStudys[0].integral;
        state.isReissue= props.clockStudys[0].isReissue;
        state.weekdays= props.clockStudys[0].weekdays;
      };
  
      const cancel = () => {
        state.visible = false;
      };
  
      const monthChange = (date) => {
        getSignList(date.year , date.month );
      };
  
      return {
        ...toRefs(state),
        show,
        cancel,
        monthChange,
        clockIn,
        clockStudyChange
      };
    },
  });
  </script>
  <style lang="less" scoped>
  .sign {
    .mixinFlex(space-between);
    .status {
      width: 220px;
      background-color: #f5f5f5;
      .top {
        height: 90px;
        background-color: #efecec;
        color: #333;
        .mixinFlex(center; center);
        position: relative;
        padding: 0 10px;
        &:after {
          content: "";
          width: 10px;
          height: 10px;
          background-color: #efecec;
          position: absolute;
          bottom: -5px;
          left: calc(50% - 5px);
          transform: rotate(45deg);
        }
      }
      .bottom {
        padding: 0 30px 40px;
        .img {
          .mixinImgWrap(153px; 100px);
          margin: 44px auto 0;
        }
        .info {
          margin-top: 36px;
          line-height: 24px;
          background-color: #fff;
          border-radius: 4px;
          padding: 4px 10px;
        }
        .btns {
          margin-top: 40px;
          .ant-btn {
            border-radius: 4px;
          }
        }
      }
    }
    .calendar {
      width: 455px;
      padding: 0 30px 20px 0;
      .box {
        width: 427px;
      }
    }
  }
  </style>
  <style lang="less">
  .sign-modal {
    .ant-modal-close-x {
      width: 28px;
      height: 50px;
      line-height: 50px;
    }
  }
  .rows {
        .mixinFlex(space-between);
        flex-wrap: wrap;
        margin-top: 15px;
        .item {
          width: 410px;
          color: #666;
          font-size: 16px;
          .label {
            color: #333;
            font-weight: 600;
          }
          &.lang {
            width: 100%;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
  </style>
  