<template>
  <div class="component-wrap">
    <div class="user">
      <div class="left">
        <div class="avatar">
          <img :src="user.portrait" alt="portrait" />
        </div>
        <div class="info">
          <div class="names">
            <div class="names-item name">
              <OpenData type="userName" :openid="user.realName" />
            </div>
            <div class="names-item" v-if="user.departmentName">
              <OpenData type="departmentName" :openid="user.departmentName" />
            </div>
            <div class="names-item" v-if="user.positionName">
              {{ user.positionName }}
            </div>
          </div>
          <div
            class="level"
            v-if="levelShow && user.levelName"
            @click="$refs.levelDetailRef.show()"
          >
            <div class="level-name">
              <img :src="user.levelImage" alt="level" />
              {{ user.levelName }}
            </div>
            <div class="level-num">
              {{ $t("mine.lv") }}
              <span>{{ user.totalIntegral }}</span
              >/{{ user.nextLevelIntegral }}
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <a-button @click="clickJump('profile')">
          {{ $t("BN_Comm_Edit") }}
          <!-- 编辑 -->
        </a-button>
        <a-button
          type="primary"
          v-if="hasIntegralMenu && hasSign"
          @click="$refs.signInRef.show()"
        >
          {{ $t("Lab_LessonDetail_T_SignIn") }}
          <!-- 签到 -->
        </a-button>
        <a-button
          style="
              background-color: rgb(255, 153, 0);
              border-color: rgb(255, 153, 0);
          "
          type="primary"
          v-if="hasClockIn"
          @click="$refs.clockStudyRef.show()"
        >
          打卡
        </a-button>
      </div>
    </div>
    <div class="main">
      <a-tabs class="mine-tabs" v-model:activeKey="activeKey">
        <a-tab-pane key="1" :tab="$t('cm_learning_data')">
          <!-- 学习数据 -->
          <div class="main-item">
            <tasks ref="tasksRef" />
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" v-if="useLearnTime">
          <!-- 学时 -->
          <template #tab>
            {{ $t("CM_Period") }}
            <a-popover trigger="hover">
              <template #content>
                <div class="popover-content">
                  {{ $t("mytime.tips") }}
                  <!-- 用于记录您完成学习获得的奖励学时 -->
                </div>
              </template>
              <ExclamationCircleOutlined style="opacity: 0.4" />
            </a-popover>
          </template>
          <div class="main-item">
            <learnTime />
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" v-if="useLearnScore">
          <!-- 学分 -->
          <template #tab>
            {{ $t("CM_LB_Credit") }}
            <a-popover trigger="hover">
              <template #content>
                <div class="popover-content">
                  {{ $t("credits.tips") }}
                  <!-- 用于记录您完成学习获得的奖励学分 -->
                </div>
              </template>
              <ExclamationCircleOutlined style="opacity: 0.4" />
            </a-popover>
          </template>
          <div class="main-item">
            <learnScore />
          </div>
        </a-tab-pane>
        <a-tab-pane key="4" v-if="hasIntegralMenu">
          <!-- 积分 -->
          <template #tab>
            {{ $t("LB_Pro_Integral") }}
            <a-popover trigger="hover">
              <template #content>
                <div class="popover-content">
                  {{ $t("integral.tips") }}
                  <!-- 用于记录您登录、分享、评论、资源学习等产生平台活跃度的积分奖励 -->
                </div>
              </template>
              <ExclamationCircleOutlined style="opacity: 0.4" />
            </a-popover>
          </template>
          <div class="main-item">
            <integral />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
    <signIn ref="signInRef" />
    <clockStudy :clockStudys="clock.clockStudys" ref="clockStudyRef" />
    <levelDetail :user="user" ref="levelDetailRef" />
  </div>
</template>

<script>
import { ref, computed, reactive, toRefs } from "vue";
import { useStore } from "vuex";
import { companyInfo, hasSign,getClockStudys } from "@/api/user";
import OpenData from "@/components/OpenData.vue";
import signIn from "./signIn/index.vue";
import clockStudy from "./clockStudy/index.vue";
import levelDetail from "./levelDetail.vue";
import { myStudyTab } from "@/utils/business";
import tasks from "./study/tasks.vue";
import learnTime from "./study/learnTime.vue";
import learnScore from "./study/learnScore.vue";
import integral from "./study/integral.vue";
export default {
  components: {
    tasks,
    learnTime,
    learnScore,
    integral,
    OpenData,
    signIn,
    clockStudy,
    levelDetail,
  },
  setup(props, { emit }) {
    const store = useStore();

    const user = computed(() => store.getters.userInfo);
    const state = reactive({
      activeKey: "1",
      useLearnTime: false,
      useLearnScore: false,
      hasIntegralMenu: false,
      hasSign: false,
      hasClockIn: false,
      clockStudys:'',
      isSignIn: false,
      levelShow: false,
      taskData: JSON.parse(JSON.stringify(myStudyTab)),
    });

    const clock = reactive({
      clockStudys:[],
    });

    hasSign().then((res) => {
      if(res.ret==0){
        state.hasSign = res.data;
      }
    });

    getClockStudys().then((res) => {
      state.hasClockIn = res.data.length>0;
      if(state.hasClockIn){
        clock.clockStudys = res.data;
      }
    });

    const tasksRef = ref(null);
    const initTab = async () => {
      const res = await companyInfo();
      const menu = res.ret === 0 ? res.data.menu : [];
      state.useLearnTime = res.data.useLearnTime == 1;
      state.useLearnScore = res.data.useLearnScore == 1;
      state.levelShow = res.data.showMemberLevel;
      state.hasIntegralMenu = menu.indexOf("integral") !== -1;
      state.taskData[0].taskType = [];
      let hasProject = menu.includes("project");
      let hasPlan = menu.includes("plan");
      if (hasProject && !hasPlan) {
        state.taskData[1].taskType = [1, 11];
      } else if (!hasProject && hasPlan) {
        state.taskData[1].menuType = "plan";
        state.taskData[1].taskType = [14];
      }
      state.taskData = state.taskData.filter((item, i) => {
        if (menu.includes(item.menuType) || i === 0) {
          i !== 0 && state.taskData[0].taskType.push(...item.taskType);
          return true;
        }
      });
      tasksRef.value.init(JSON.parse(JSON.stringify(state.taskData)));
    };
    initTab();

    const clickJump = (key) => {
      emit("emitJump", { key });
    };

    return {
      user,
      ...toRefs(state),
      tasksRef,
      clickJump,
      clock,
    };
  },
};
</script>

<style lang="less" scoped>
.component-wrap {
  .user {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(148, 148, 148, 0.1);
    margin-bottom: 20px;
    padding: 24px;
    .mixinFlex(space-between, center);
    .left {
      .mixinFlex(flex-start, center);
      .avatar {
        .mixinImgWrap(60px; 60px);
        border-radius: 50%;
        margin-right: 20px;
      }
      .names {
        .mixinFlex();
        &-item {
          font-size: 16px;
          color: #999;
          font-weight: 400;
          line-height: 22px;
          padding: 0 8px;
          position: relative;
          &::before {
            content: "";
            width: 1px;
            height: 14px;
            background-color: #f4f4f4;
            position: absolute;
            left: 0;
            top: 4px;
          }
          &.name {
            color: #333;
            font-weight: 600;
            padding-left: 0;
            &::before {
              content: none;
            }
          }
        }
      }
      .level {
        margin-top: 12px;
        padding-left: 16px;
        cursor: pointer;
        .mixinFlex(flex-start, center);
        &-name {
          display: inline-block;
          min-width: 52px;
          height: 16px;
          background: linear-gradient(
            117deg,
            #467aff 0%,
            #85e2ff 99.99%,
            #ff7b7b 100%
          );
          font-size: 12px;
          line-height: 16px;
          color: #fff;
          padding: 0 8px 0 18px;
          border-radius: 0 8px 8px 0;
          position: relative;
          img {
            width: 30px;
            height: 30px;
            position: absolute;
            left: -16px;
            top: -7px;
          }
        }
        &-num {
          margin-left: 10px;
          color: #999;
          font-size: 12px;
          line-height: 20px;
          span {
            color: #f90;
          }
        }
      }
    }
    .right {
      ::v-deep(.ant-btn) {
        border-radius: 4px;
        margin-left: 16px;
      }
    }
  }
  .main {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px 0 rgba(148, 148, 148, 0.1);
    padding: 24px 10px;
    .mine-tabs {
      ::v-deep(.ant-tabs-bar) {
        margin: 0;
        border-bottom: 1px solid #f4f4f4;
        margin-left: 14px;
        margin-right: 14px;
        .ant-tabs-tab {
          padding: 0 0 9px;
          line-height: 26px;
        }
        .ant-tabs-ink-bar {
          height: 4px;
          border-radius: 2px;
        }
      }
    }
    &-item {
      margin: 0 14px;
    }
  }
}
</style>
